"use client";

import React, { Suspense, useCallback, useEffect, useState } from "react";
import styles from "./EnrollButton.module.scss";
import cn from "clsx";
import CommonButton from "@/components/common/Analytics/CustomButton";

const EnrollButton = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    if (window.location.pathname === "/") {
      if (window.scrollY > 0.7 * window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(true);
    }
  }, [isVisible]);


  useEffect(() => {
    if (data?.CTA?.enabled && window.innerWidth < 768) {
      toggleVisibility();
      window.addEventListener("scroll", toggleVisibility);
    }

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className={cn({ [styles.root]: isVisible })}>
        <CommonButton
          className={cn("scrollbtn scrollButton", styles.scrollButton, {
            [styles.show]: isVisible,
            ["!hidden"]: !isVisible,
          })}
          href={data?.CTA?.url}
          size="l"
          bg="s"
          color="h" // when bg banner is white, button bg is yellow & hover is white
          icon={"/_a/icons/left-arrow.svg"}
        >
          {data?.CTA.text}
        </CommonButton>
    </div>
  );
};

export default EnrollButton;
