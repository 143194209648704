"use client";

import Image from "next/image";
import HamburgerLogo from "~/icons/heroicons-solid_menu.svg";
import CloseLogo from "~/icons/close.svg";
import { SvgToUse } from "@/components/atoms/Buttons/WarningButton";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./header.module.scss";
import cn from "clsx";
import { searchPrograms } from "@molecules/ProgramMenu/constants";
import { pushToDataLayer } from "@/helpers/gtag";
import { GAEvent, GA_EVENTS } from "@/helpers/gaEvents";
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import Script from "next/script";

const toggleScroll = (flag: boolean) => {
  const htmlEl = document.querySelector("html");
  if (flag) {
    htmlEl.style.overflowY = "hidden";
  } else {
    htmlEl.style.overflowY = "auto";
  }
};

export const LinkButton = ({ button, CN }: any) => {
  return (
    <div className={cn(styles.linkBtn, CN)}>
      <a
        href={button?.url || "/"}
        target={button?.target}
        onClick={(e) => toggleScroll(false)}
        className="flex items-center justify-center"
      >
        <span className="uppercase font-raleway mr-3">{button?.text}</span>
        <SvgToUse type={"normal"} iconDirection={"right"} />
      </a>
    </div>
  );
};

export default function Header({
  headerMenu,
  programMenuList,
  programDomains,
}: {
  programMenuList?: any;
  programDomains?: any;
  headerMenu: { MenuItem: headerMenu[]; Logo: any; CTA: any; LoginCTA: any };
}) {
  const { CALL_US_NAVBAR } = GAEvent;
  const [menuOpen, setMenuOpen] = useState(false);
  const [results, setResults]: any = useState([]);
  const [showSearchBox, toggleSearch] = useState(false);
  const [programMenuOpen, setProgramMenuOpen] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isDelayingEngatiScript, setIsDelayingEngatiScript] = useState(false)
  const headerRef = useRef<HTMLInputElement>(null);
  const programMenuRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 15) {  
        setIsDelayingEngatiScript(true)
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        if (!programMenuOpen) {
          if (window.scrollY < lastScrollPosition) {
            setIsHeaderFixed(true);
          } else {
            setIsHeaderFixed(false);
          }
        }
      } else {
        setIsHeaderFixed(false);
      }
      lastScrollPosition = window.scrollY;
    };
    let lastScrollPosition = 0;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [programMenuOpen]);

  const toggleMenu = useCallback(() => {
    toggleScroll(!menuOpen);
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  function calculateTopForMenu(e) {
    toggleScroll(true);
    const headerHeight = headerRef?.current?.clientHeight;
    programMenuRef.current.style.height = `calc(100vh - calc(${headerHeight}px + ${
      isHeaderFixed ? 0 : 1.25
    }rem))`;
    programMenuRef.current.style.top = `calc(${headerHeight}px + ${
      isHeaderFixed ? 0 : 1.25
    }rem)`;
  }

  const SearchBox = (
    <div className={cn("relative program-menu", styles.searchBoxContainer)}>
      <input
        type="text"
        autoFocus
        className="text-sm lg:text-xs"
        ref={ref}
        onKeyUp={(e) => {
          const results = searchPrograms(
            e.currentTarget.value,
            programMenuList?.MAIN_LIST,
          );
          if (results.length == 0)
            setResults([{ Title: "No programs found." }]);
          else setResults(results);
          setProgramMenuOpen(false);
        }}
        // onBlur={() => setResults([])}
      />
      {
        <div
          onClick={() => {
            toggleSearch(false);
            setResults([]);
            if (ref.current) ref.current.value = "";
          }}
          className={cn("absolute", "headFootSprite", styles.searchClose)}
        ></div>
      }

      {!!results?.length && (
        <div className={cn(styles.resultsContainer)}>
          <div
            className={cn(
              styles.results,
              "bg-white font-raleway text-xs font-semibold  overflow-y-auto z-100",
            )}
          >
            {results.map((item: any, i: number) => (
              <div className={styles.resultsItem} key={i}>
                {item.url ? (
                  <a title={item?.Title} href={`/${item?.url}`}>
                    {item?.Title}
                  </a>
                ) : (
                  item?.Title
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );


  return (
    <>
      {isDelayingEngatiScript && (
        <Script
          id="engati-script"
          dangerouslySetInnerHTML={{
            __html: `!function(e,t,a){var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.defer=!0, n.type="text/javascript",n.src=t+"/static/js/widget.js?config="+JSON.stringify(a),c.appendChild(n)}(document,"https://app.engati.com",{bot_key:"bf113f277dd14593",welcome_msg:true,branding_key:"default",server:"https://app.engati.com",e:"p" });`,
          }}
          strategy="lazyOnload"
        />
      )}
           
      <header
        ref={headerRef}
        className={cn("container", {
          [styles.fixedHeader]: isHeaderFixed,
          ["z-[1]"]: !isHeaderFixed,
        })}
      >
        <div
          className={cn(
            "mt-2 bg-primary-blue text-white mb-3 xl:mt-5 xl:flex xl:justify-between xl:items-center",
            styles.containerRadius
          )}
        >
          <div className="flex items-center justify-between ">
            {/* University logo */}
            {headerMenu?.Logo?.enabled && (
              <a
                className={cn("block", styles.logo)}
                href="/"
                target={headerMenu.Logo?.target || "_self"}
              >
                <Image
                  src={headerMenu.Logo.source.data[0].attributes.url}
                  alt={headerMenu.Logo?.alt}
                  width={124}
                  height={44}
                />
              </a>
            )}

            <div className="pt-6 pr-[28px] pb-[27px] flex lg:hidden laptop:pt-[26px] laptop:pr-[33px] laptop:pb-[25px]">
              <a
                href="tel:18001023434"
                className={
                  "flex items-center border-[1.5px] border-[#1a325d] mr-4 px-3 py-2 text-[#1a325d] bg-[#ffc907] rounded-[50px]"
                }
                onClick={() => {
                  pushToDataLayer(GA_EVENTS.get(CALL_US_NAVBAR))
                }}
              >
                <Image
                  src="/_a/icons/outgoing_call.svg"
                  alt="Call"
                  width={12}
                  height={14}
                  className={cn(styles.callImg, "mr-1.5 !h-[14px] !w-[12px]")}
                />
                <span className={cn(styles.callTitle)}>Call Us</span>
              </a>
              <div
                onClick={toggleMenu}
                className={cn(styles.searchImage, styles.searchIconNew, "headFootSprite", {
                  ["absolute top-1/2 -translate-y-1/2"]: showSearchBox,
                })}
              />
              <button onClick={toggleMenu}>
                {menuOpen ? (
                  <Image src={CloseLogo} alt="hamburger-close" />
                ) : (
                  <Image src={HamburgerLogo} alt="hamburger-menu" />
                )}
              </button>
            </div>
          </div>

          <DesktopMenu
            SearchBox={SearchBox}
            calculateTopForMenu={calculateTopForMenu}
            headerMenu={headerMenu}
            menuOpen={menuOpen}
            programDomains={programDomains}
            programMenuList={programMenuList}
            programMenuOpen={programMenuOpen}
            programMenuRef={programMenuRef}
            setProgramMenuOpen={setProgramMenuOpen}
            showSearchBox={showSearchBox}
            toggleScroll={toggleScroll}
            toggleSearch={toggleSearch}
          />
        </div>

        <div
          className={cn(
            "container",
            styles.mobileContainer,
            menuOpen ? "block" : "hidden"
          )}
        >
          <MobileMenu
            SearchBox={SearchBox}
            headerMenu={headerMenu}
            menuOpen={menuOpen}
            programDomains={programDomains}
            programMenuList={programMenuList}
            programMenuOpen={programMenuOpen}
            results={results}
            setProgramMenuOpen={setProgramMenuOpen}
          />
        </div>
      </header>
    </>
  )
}
