import styles from "./header.module.scss";
import cn from "clsx";
import Image from "next/image";
import Button from "@/components/atoms/Button";
import Search1Logo from "~/icons/search1.svg";
import DownLtLogoBlue from "~/icons/down-lt.svg";
import dynamic from "next/dynamic";
import { Suspense } from "react";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { ModalTypes } from "@/types/modal";

const ProgramMenu = dynamic(() => import("@molecules/ProgramMenu/ProgramsMenu"));

export default function MobileMenu({
	menuOpen, 
	headerMenu, 
	programMenuOpen,
	setProgramMenuOpen,
	programMenuList,
	programDomains,
	SearchBox,
	results,
}){

	return (
    <div
      className={cn(styles.mobileMenu, "flex flex-col items-start", {
        ["hidden"]: !menuOpen,
      })}
    >
      <div className={styles.btnContainerTop}>
        <div className={cn("cursor-pointer relative", styles.searchBox)}>
          <Image
            src={Search1Logo}
            alt="search"
            className={cn("absolute", styles.searchIcon)}
          />
          {SearchBox}
        </div>

        {!results?.length &&
          !programMenuOpen &&
          headerMenu?.MenuItem?.filter((x) => x.enabled)?.map(
            ({ Item, SubItem }, index) => (
              <div
                className={cn("flex items-center", styles.menuItem)}
                key={index}
              >
                <a
                  href={Item.url}
                  target={Item.target || "_self"}
                  key={Item.id}
                  className="flex justify-center items-center"
                  onClick={(e) => {
                    if (index == 0) {
                      e.preventDefault();
                      setProgramMenuOpen(true);
                    }
                  }}
                >
                  <span className="uppercase mr-3">{Item.text}</span>
                  {index == 0 && (
                    <Image
                      className={styles.submenuArrow}
                      src={DownLtLogoBlue}
                      alt="more items"
                    />
                  )}
                </a>
              </div>
            ),
          )}
      </div>

      <div className={cn(styles.programMenu)}>
        <Suspense fallback={null}>
          <ProgramMenu
            isMobile
            goBack={() => {
              setProgramMenuOpen(false)
            }}
            programMenu={programMenuList}
            programDomains={programDomains}
            programMenuOpen={programMenuOpen}
          />
        </Suspense>
      </div>
      
      {!results?.length && !programMenuOpen && (
        <div
          className={cn(
            styles.btnContainer,
            "mt-auto flex flex-col items-start",
          )}
        >
          {headerMenu?.LoginCTA?.enabled && (
              <CommonButton
                modalType={ModalTypes.LoginModal}
                type={"button"}
                color={"b"}
                icon="/_a/icons/left-arrow.svg"
                hoverIcon="/_a/icons/left-arrow.svg"
                bg="o"
                className={cn(styles.mr)}
                imageLoadingStrategy="lazy"
              >
                {headerMenu.LoginCTA.text}
              </CommonButton>
          )}
          {headerMenu?.CTA?.enabled && (
            <Button
              buttonProps={{
                href: headerMenu?.CTA?.url,
                target: headerMenu?.CTA?.target?.[0],
              }}
              bg="s"
              color="y"
              icon="/_a/icons/left-arrow.svg"
            >
              {headerMenu.CTA.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

