import styles from "./header.module.scss";
import cn from "clsx";
import DownLtLogo from "~/icons/down-lt-white.svg";
import Image from "next/image";
import CommonButton from "@/components/common/Analytics/CustomButton";
import dynamic from "next/dynamic";
import { GAEvent, GA_EVENTS, GA_EVENTS_PAYLOAD } from "@/helpers/gaEvents";
import { Suspense } from "react";
import { ModalTypes } from "@/types/modal";

const ProgramMenu = dynamic(() => import("@molecules/ProgramMenu/ProgramsMenu"));

const { NAVBAR_ENROLL_NOW, CALL_US_NAVBAR } = GAEvent
 export default function DesktopMenu({
	menuOpen, 
	headerMenu, 
	programMenuOpen,
	toggleScroll,
	calculateTopForMenu,
	setProgramMenuOpen,
	programMenuRef,
	programMenuList,
	programDomains,
	toggleSearch,
	showSearchBox,
	SearchBox,
	}){
    return (
      <div className={cn(styles.desktopMenu)}>
        <div
          className={cn(
            `list-none text-base font-raleway tracking-widest flex items-center ${
              menuOpen ? "flex-col xl:flex-row" : ""
            }`,
            styles.menuContainer
          )}
        >
          {headerMenu?.MenuItem?.filter((x) => x.enabled)?.map(
            ({ Item, SubItem }, index) => (
              <div
                className={cn("flex items-center group w-max", styles.menuItem)}
                key={index}
              >
                {index !== 0 ? (
                  <a
                    href={Item.url || ""}
                    target={Item.target || "_self"}
                    key={Item.id}
                    className="flex justify-center items-center"
                  >
                    <span
                      className={cn(
                        "mr-1 uppercase laptop:font-medium",
                        styles.menuText,
                        {
                          ["flex gap-1 items-center"]: index === 0,
                          [styles.openMenu]: index === 0 && programMenuOpen,
                        }
                      )}
                    >
                      {Item.text}
                      {index == 0 && (
                        <Image src={DownLtLogo} alt="more items" />
                      )}
                    </span>
                  </a>
                ) : (
                  <div
                    key={Item.id}
                    className="flex justify-center items-center cursor-pointer"
                    onClick={(e) => {
                      calculateTopForMenu(e)
                      if (programMenuOpen) toggleScroll(false)
                      setProgramMenuOpen(!programMenuOpen)
                    }}
                  >
                    <span
                      className={cn(
                        `mr-1 uppercase laptop:font-medium ${index == 0 ? 'headFootSprite serviceMenu pr-5' : ''}`,
                        styles.menuText,
                        {
                          ["flex gap-1 items-center"]: index === 0,
                          [styles.openMenu]: index === 0 && programMenuOpen,
                        }
                      )}
                    >
                      {Item.text}
                    </span>
                  </div>
                )}

                {index == 0 && (
                  <div
                    ref={programMenuRef}
                    className={cn(
                      "container w-full fixed",
                      programMenuOpen ? styles.programMenu : '',
                    )}
                  >
                    <Suspense fallback={null}>
                      <ProgramMenu
                        programMenu={programMenuList}
                        programDomains={programDomains}
                        closeMenu={() => {
                          setProgramMenuOpen(false)
                          toggleScroll(false)
                        }}
                        programMenuOpen={programMenuOpen}
                      />
                    </Suspense>
                  </div>
                )}
              </div>
            )
          )}
          <div className={cn("cursor-pointer relative", styles.searchBox)}>
            <div
              onClick={() => toggleSearch((v) => !v)}
              className={cn(styles.searchIcon, styles.searchIconNew, "headFootSprite", {
                ["absolute top-1/2 -translate-y-1/2"]: showSearchBox,
              })}
            />
            {showSearchBox && SearchBox}
          </div>
            <CommonButton
              className={cn(styles.callUsBtn, "flex mr-5 font-semibold !py-5 !px-4")}
              GAData={GA_EVENTS.get(CALL_US_NAVBAR)}
              modalType={ModalTypes.CallUsModal}
              type={"button"}
            >
              <Image
                src="/_a/icons/outgoing_call.svg"
                alt="Call"
                width={14}
                height={14}
                className={"mr-2"}
              />
              <span className="font-bold mr-0">Call Us</span>
            </CommonButton>
          {headerMenu?.LoginCTA?.enabled && (
              <CommonButton
                modalType={ModalTypes.LoginModal}
                type={"button"}
                color={"w"}
                bg="o"
                className={cn(styles.mr, styles.studentLogin, "headFootSprite")}
                imageLoadingStrategy="lazy"
              >
                {headerMenu.LoginCTA.text}
              </CommonButton>
          )}
          {headerMenu?.CTA?.enabled && (
              <CommonButton
                GAData={GA_EVENTS_PAYLOAD.get(NAVBAR_ENROLL_NOW)}
                CTATarget={headerMenu?.CTA?.target?.[0]}
                href={headerMenu?.CTA?.url}
                bg="s"
                color="y"
                icon="/_a/icons/left-arrow.svg"
                className={styles.mr}
              >
                {headerMenu.CTA.text}
              </CommonButton>
          )}
        </div>
      </div>
    )
}
